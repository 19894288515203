








































































































































































































































.card-datetime {
  padding-top: 0 !important;
  .v-tabs-items {
    margin-top: 0;
  }
  .v-toolbar__content, .v-toolbar__extension {
    height: 40px;
    padding-right: 4px;
    padding-top: 0px;
  }
}
